.navbar {
    position: sticky;
    top: 0;
    background-color: var(--background);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    box-shadow: 0px 1px rgb(127.5, 127.5, 127.5);
}
.navbar-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.navbar-menu li {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 30px;
    list-style: none;
}
.nav-link {
    text-decoration: none;
    color: var(--text);
    font-size: 20px;
}
.nav-link:hover {
    color: var(--primary);
}