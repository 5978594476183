.project-card {
    border-radius: 25px;
    border: var(--accent) 7px solid;
    max-width: 300px;
    min-width: 300px;
    max-height: 500px;
    min-height: 500px;
    margin: 20px;
    background-color: var(--secondary);
    position: relative;
}
.project-logo {
    height: auto;
    margin-bottom: 10px;
    object-fit: contain;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    justify-content: center;
    display: grid;
    border-bottom: var(--accent) 7px solid;
}
.project-logo img {
    height: 180px;
}
.project-card p {
    margin: 7px;
}
.project-card h2 {
    color: var(--primary);
    margin: 7px;
}
.project-card a {
    display:  grid;
    background-color: var(--accent);
    color: var(--text);
    justify-content: center;
    text-align: center;
    padding: 5px;
    text-decoration: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-inline: auto;
}
.project-card a:hover {
    color: var(--secondary);
}