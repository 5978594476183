body {
  margin: 0;
  background-color: var(--background);
}
h1, h2, h3, p {
  color: var(--text);
}
@media (prefers-color-scheme: light) {
  :root {
    --text: #130614;
    --background: #fcf7fd;
    --primary: #c5448d;
    --secondary: #efcdce;
    --accent: #b73841;
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --text: #f8ebf9;
    --background: #070208;
    --primary: #bb3a83;
    --secondary: #331012;
    --accent: #c74850;
  }
}

